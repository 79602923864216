<template>
  <div class="images-items">
    <ul class="list">
      <li v-for="(item,index) in list" :class="[{ active: index==current}, 'item']" :key="index">
        <img :src="item.img" alt />
      </li>
    </ul>
    <div class="spans">
      <a
        v-for="(o,oIndex) in spans"
        :key="oIndex"
        :class="{ active: oIndex==current}"
        @click="clickSlide(oIndex)"
      ></a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        { img: require("@/assets/images/login/banner1.jpg") },
        { img: require("@/assets/images/login/banner2.jpg") }
      ],
      spans: [{}, {}], //图片对应原点数量
      current: 0,
      time: 0
    };
  },
  mounted() {
    this.animation();
  },
  methods: {
    animation() {
      this.time = setInterval(() => {
        this.current++;
        if (this.current == this.list.length) {
          this.current = 0;
        }
      }, 3000);
    },
    clickSlide(index) {
      this.current = index;
      clearInterval(this.time);
      this.animation();
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.images-items {
  &,
  .list,
  .list img {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .list {
    position: relative;
    z-index: 1;
    li {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      opacity: 0;
      animation: hide 0.4s ease-out;
      &.active {
        opacity: 1;
        animation: show 0.4s ease-in;
        transition-delay: 0s;
      }
    }
  }
  .spans {
    position: absolute;
    right: 38px;
    bottom: 80px;
    width: 8px;
    z-index: 9;
    a {
      margin-top: 10px;
      display: block;
      width: 8px;
      height: 8px;
      text-indent: -999em;
      background: #fff;
      opacity: 0.5;
      border-radius: 10px;
      cursor: pointer;
      &.active {
        opacity: 1;
      }
    }
  }
}
@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>